<!-- 店铺变更归属人 -->
<template>
    <el-form ref="form" label-width="80" size="mini" :inline="true">
        <el-row>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('归属人')" label-width="60" size="mini">
                    <el-autocomplete size="mini" clearable
                        v-model="userName"
                        :fetch-suggestions="searchUser"
                        :highlight-first-item="true"
                        :placeholder="this.msg('搜索归属人')"
                        :trigger-on-focus="false"
                        @select="selectUser"
                        @clear="clearUsername"
                    ></el-autocomplete>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'storeEdit',
    data(){
        return {
            userName:null,
            userId:null,
            shopIds:null,
        }
    },
    props:{
        store: Object
    },
    methods:{
        init(shopIds){
            this.shopIds = shopIds;
            this.userName = null;
            this.userId = null;
        },
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        selectUser(item) { //获取选中的用户
            this.userId = item.id;
            this.userName = item.value;
        },
        clearUsername(){
            this.userName = null;
            this.userId = null;
        },
        onSubmit(){
            if(!this.userName || !this.userId){
                this.$message(this.msg("请选择用户"));
                return;
            }

            var _this = this;
            this.$axios.post(this.$kit.api.shop.change+'?userId='+this.userId+"&shopIds="+this.shopIds)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.$emit("close-dg", true);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>

</style>