<!-- 店铺管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template v-slot="scope">
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doBind()" v-if="hasPermission('shop:bind')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bangdingdianpu" font-size="27px;"/>
                            <span class="txt">{{msg('绑定')}}
                                <br v-if="scope.ctrl.expand" />
                            {{msg("虾皮店铺")}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="syncShop()" v-if="hasPermission('shop:sync')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shuaxin1" font-size="27px;"/>
                            <span class="txt">{{msg('同步')}}
                                <br v-if="scope.ctrl.expand" />
                            {{msg("店铺信息")}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="refreshToken()" v-if="hasPermission('shop:sync')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-huotaitongbu" font-size="27px;"/>
                            <span class="txt">{{msg('刷新token')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="shopQtyRefresh()" v-if="hasPermission('shop:bind')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-dianpushangxian" font-size="27px;"/>
                            <span class="txt">{{msg('店铺上线')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="getLink()" v-if="hasPermission('shop:link')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tiqulianjie" font-size="27px;"/>
                            <span class="txt">{{msg('提取链接')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editName()" v-if="hasPermission('shop:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('店铺别称')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doChange()" v-if="hasPermission('shop:change')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-zhanghu2" font-size="27px;"/>
                            <span class="txt">{{msg('变更')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('shop:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                    <div style="margin-left:20px;margin-top:-5px;line-height: 1;color:#F56C6C;font-weight:bold"><span>{{msg('可授权店铺数：')}}</span><span style="font-size:30px">{{shopQty}}</span><span> {{msg('家，超出授权数量的店铺会处于离线状态')}}</span></div>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('店铺名')">
                        <el-input v-model="scope.query.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('店铺ID')">
                        <el-input v-model="scope.query.shopId" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('归属人')">
                        <el-input v-model="scope.query.creater" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('归属公司')">
                        <el-input v-model="scope.query.companyName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('使用状态')">
                        <el-select v-model="scope.query.used" placeholder="请选择">
                            <el-option :key="0" label="正常" :value="0"></el-option>
                            <el-option :key="1" label="离线" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('店铺状态')">
                        <el-select v-model="scope.query.status" placeholder="请选择">
                            <el-option :key="0" label="正常" value="NORMAL"></el-option>
                            <el-option :key="1" label="冻结" value="FROZEN"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('授权日期')">
                        <el-date-picker  v-model="scope.query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('过期时间')">
                        <el-date-picker  v-model="scope.query.expireTime" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('离线时间')">
                        <el-date-picker  v-model="scope.query.usedTime" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list(true)" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="shops"
                :columns="columns"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>
    
        <el-dialog :title="msg('变更归属人')" :visible.sync="changeVisible" :fullscreen="isMinScreen"  @opened="openedChange" 
            :close-on-click-modal="false" 
                width="400px">
            <change ref="editRateDg" v-on:close-dg="closeChangeDialog"/>
        </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Change from "./ShopChange.vue";

export default {
    name: 'shop',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Change:Change,
    },
    mounted:function(){
        this.buildColumns();
        this.list();
        this.getShopQty();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                expireTime:[],
                usedTime:[],
                companyName:null,
                used:null,
                status:null,
                shopId:null,
            },
            showQuery: false,
            curr: null,
            columns: [],
            shops: [],
            dgTitle: '',
            dgVisible: false,
            shopQty:0,
            changeVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 60},
                {field: 'id', title: this.msg('ID'), minWidth: 80, sortable: true},
                {field: 'type', title: this.msg('店铺类型'), minWidth:100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let type = this.dicts.shopTypes[row.type];
                            return [
                                <el-tag type={type?type.type:''}>{type?type.label:row.type}</el-tag>
                            ];
                        },
                    }
                },
                {field: 'shopId', title: this.msg('店铺ID'), minWidth: 110, sortable: true},
                // {field: 'thumb', title: this.msg('缩略图'), minWidth: 100, sortable: true,
                //     slots: {
                //         default: ({ row }) => {
                //             let thumb = row['thumb']
                //             return thumb?[
                //                 <el-image  style="width: 60px; height: 60px" src={this.$kit.api.imgView+thumb} preview-src-list={[this.$kit.api.imgView+thumb]}></el-image>
                //             ]: [thumb];
                //         },
                //     }
                // },
                {field: 'name', title: this.msg('店铺名'), minWidth: 130, sortable: true},
                {field: 'actualName', title: this.msg('店铺别称'), minWidth: 150, sortable: true},
                {field: 'ownerName', title: this.msg('归属人'), minWidth: 130, sortable: true},
                {field: 'company', title: this.msg('归属公司'), minWidth: 130, sortable: true},
                {field: 'syncRes', title: this.msg('同步结果'), minWidth:150, sortable: true},
                {field: 'status', title: this.msg('店铺状态'), minWidth:120, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let status = row.status;
                            return [
                                <el-tag type={status=='NORMAL'?'success':'danger'}>{status=='NORMAL'?'正常':status=='已过期'?'已过期':'冻结'}</el-tag>
                            ];
                        },
                    }
                },
                {field: 'used', title: this.msg('使用状态'), minWidth:120, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let type = row.used;
                            return [
                                <el-tag type={type==1?'danger':'success'}>{type==1?'离线':'正常'}</el-tag>
                            ];
                        },
                    }
                },
                {field: 'usedTime', title: this.msg('离线时间'), minWidth:120, sortable: true},
                {field: 'created', title: this.msg('授权时间'), minWidth:130, sortable: true},
                {field: 'expireTime', title: this.msg('授权过期时间'), minWidth:130, sortable: true},
            ]
        },
        //查询店铺
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.shop.list, param)
                .then((result) => {
                    if(result.status){
                        this.shops = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        getShopQty(){//获取当前用户可绑定店铺数量
            this.$axios.get(this.$kit.api.shop.getShopQty)
                .then((result) => {
                    if(result.status){
                        this.shopQty = result.data;
                    }
                });
        },
        shopQtyRefresh(){//店铺上线
            this.$confirm(this.msg('超额绑定的店铺会处于离线状态，且无法同步数据；建议可删除不经营的店铺，将店铺数量控制在额度范围内，或进入续费页面自行扩充可授权的店铺数量！'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                var selectes = this.$refs.dataTable.getCheckboxRecords();
                let arr = [];
                for (let i = 0; i < selectes.length; i++) {
                    const element = selectes[i];
                    let ownerId = element.ownerId;
                    if(arr.indexOf(ownerId) == -1){
                        arr.push(ownerId);
                    }
                }
                this.$axios.post(this.$kit.api.shop.shopQtyRefresh,arr)
                .then((result) => {
                    if(result.status){
                        this.$message(this.msg('更新完成'));
                        this.list();
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //绑定店铺
        doBind(){
            this.$axios.get(this.$kit.api.shop.checkShopNum)
                .then((result) => {
                    if(result.status){
                        window.location.href=this.$kit.api.shop.bind;
                    }else{
                        this.$message.error(result.msg);
                    }
                });
            // window.location.href=this.$kit.api.shop.bind;
        },
        //同步店铺信息
        syncShop(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            if(selectes.length > 1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.$confirm(this.fmt('确定更新店铺《%s》的信息吗?', [selectes[0].name]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                 this.$axios.get(this.$kit.api.shop.syncShop+"?shopId="+selectes[0].shopId)
                .then((result) => {
                    this.$message(result.msg || this.msg('同步失败'));
                    this.list();
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //编辑店铺名
        editName(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.$prompt('请输入新的店铺别称', '编辑店铺别称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /.+/,
                inputErrorMessage: '店铺名不能为空'
            }).then(({ value }) => {
                let para = {id: selectes[0].id, actualName: value};
                this.$axios.post(this.$kit.api.shop.editName, para)
                .then((result) => {
                    this.$message(result.msg || this.msg('更新失败'));
                    this.list();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消修改'
                });       
            });
        },
        refreshToken(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }
            var _this = this;
            _this.$axios.post(_this.$kit.api.shop.refreshToken+"?id="+selectes[0].id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('操作成功'));
                    _this.list();
                });
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除店铺《%s》吗?', selectes.map(e => e.name)), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.shop.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.list();
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doChange(){//变更店铺归属人
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$confirm(this.fmt('本操作将变更店铺归属人，只能更改店铺归属人，已同步的订单不会变更，店铺更改后无法撤销，是否继续操作?', selectes.map(e => e.name)), this.msg('提示'), {
                confirmButtonText: this.msg('是'),
                cancelButtonText: this.msg('否'),
                type: 'warning'
            }).then(() => {
                this.changeVisible = true;
            }).catch((err) => {
            });
        },
        getLink(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$confirm('是否要提取店铺采购链接?', this.msg('提示'), {
                confirmButtonText: this.msg('是'),
                cancelButtonText: this.msg('否'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.shop.getLink+"?shopId="+selectes[0].shopId)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('提取成功'));
                });
            }).catch((err) => {
                this.$message(this.msg('取消操作'));
            });
        },
        openedChange(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
                this.$nextTick(() => {
                    this.$refs.editRateDg.init(selectes.map(s=>s.id).join(","));
                });
        },
        closeChangeDialog(){
            this.changeVisible = false;
            this.list();
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        doExport(){
            this.$refs.dataTable.exportData({ filename:"店铺信息",type: 'csv' })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
