import { render, staticRenderFns } from "./ShopChange.vue?vue&type=template&id=05a68f65&scoped=true"
import script from "./ShopChange.vue?vue&type=script&lang=js"
export * from "./ShopChange.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a68f65",
  null
  
)

export default component.exports